<template>
  <AppPageCard>
    <template #card-title> Document Archived</template>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "CompanyDocumentArchivedPage",
  components: { AppPageCard },
};
</script>

<style scoped></style>
